import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import CreateUserModal from "./CreateUserModal";
import UpdatePasswordModal from "./swt-update-password";
import UpdateUserGroupsModal from "./UpdateUserGroupsModal";
import AdminTableView from "./AdminTableView";
import Loading from "../swt-loading";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { formatDateTimeUTC } from '../UtilityFunctions';
import { Roles, assignUserRole } from "../../utility-components/UserUtils";
import { adminUsersTableColumns } from "./TableHelpers";

const SERVER_ERRORED_MESSAGE = "There was a server error during your request.";
const EL_CAMINO_SUITE = ['shifted', 'speedn', 'idling', 'fueln'];

export default function AdminUsers(props) {
  const { apiURL, db, dbDisplayName, user, isTelematics } = props;

  const [selectedUser, setSelectedUser] = useState(null);
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [updatedUsers, setUpdatedUsers] = useState([]);
  const [userDBs, setUserDBs] = useState([]);

  const [users, setUsers] = useState(([]));
  const [groups, setGroups] = useState([]);
  const [databases, setDatabases] = useState(() => getDatabases());
  const [databaseProducts, setDatabaseProducts] = useState([]);

  const skipPageResetRef = useRef(true);

  let hiddenColumns = [];
  switch (user.role) {
    case Roles.FleetAdmin:
      hiddenColumns = ['primary_db', 'location_edit_tool', 'dev_tools', 'admin'];
      // Hide any el camino suite products that aren't already purchased for the database from fleet admins
      EL_CAMINO_SUITE.forEach(ecp => {
        if (!databaseProducts.find(p => p === ecp)) {
          hiddenColumns.push(ecp)
        }
      });
      break;
    case Roles.PartnerAdmin:
      hiddenColumns = ['location_edit_tool', 'dev_tools'];
       // Hide any el camino suite products that aren't already purchased for the database from partner admins
       EL_CAMINO_SUITE.forEach(ecp => {
        if (!databaseProducts.find(p => p === ecp)) {
          hiddenColumns.push(ecp)
        }
      });
      break;
    case Roles.SuperAdmin:
      hiddenColumns = ['location_edit_tool', 'dev_tools'];
      break;
    case Roles.SuperAdminDeveloper:
      hiddenColumns = [];
      break;
    default:
      hiddenColumns = ['primary_db', 'location_edit_tool', 'dev_tools', 'admin', 'shifted', 'speedn', 'idling', 'fueln'];
  }
  // ensure the appropriate ezev column is shown
  hiddenColumns.push(isTelematics ? "ezev-fx" : "ezev-td");

  const BoolTableCell = useCallback((props) => {
    let title = null;
    // Conditionally render a tooltip for the checkbox. This copy cascades in an intentionally specific override order.
    if (props.aboveAccessLevel) title = "This user's permission level is too low to access this product."
    if (props.notPurchased) title = "Your fleet has not purchased this product.";
    if (props.updateForbidden) title = "You cannot make changes to this user's products.";
    return (
      <input
        className='swt-admin-table-input'
        type="checkbox"
        accessor={props.accessor}
        style={{ color: props.bool ? "black" : "transparent" }}
        checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool}
        disabled={props.disabled ? true : false}
        email={props.email}
        onChange={props.handleOnClick}
        title={title}
      />
    )
  }, []);

const TableButton = useCallback((props) => 
  <S.InnerTableButton data-testid={props.data_testid} disabled={props.disabled} color={props.color} onClick={props.handleClick}>
    {props.label}
  </S.InnerTableButton>,[]);

const showUpdateGroupsModal = useCallback((username) => {
  setShowGroupsModal(!showGroupsModal);
  setSelectedUser(username);
}, [showGroupsModal]);

useEffect(() => {
  getUserRecords();
  getDatabaseProducts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[apiURL, db, user])

useEffect(() => {
  const url = `${props.apiURL}getGroups/${props.db}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${props.user.token}` },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.status === "error") 
        alert(SERVER_ERRORED_MESSAGE);
      else {
        const obj = data.data;
        obj.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setGroups(obj);
      }
    })
    .catch((err) => console.error(err));
}, [props.apiURL, props.db, props.user])

function getUserRecords () {
  let url = `${apiURL}getUsers/`;
  if (db) url = `${apiURL}getUsers?dbName=${db}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${user.token}` },
  })
    .then(props.handleFetchErrors)
    .then((res) => res.json())
    .then((json) => {
      if (json.status === "error") 
        alert(SERVER_ERRORED_MESSAGE);
      else {
        let sorted = json.data.sort((a, b) => (a.email > b.email ? -1 : 1));
        getSelectUserDatabases(json.data);
        sorted.forEach((u) => {
          u.role = assignUserRole(u); // Assign each user their role
          //Keep track of original product list to reference which products have been added/deleted
          if (!u.products) u.products = [];
          u.original_product_list = JSON.parse(JSON.stringify(u.products));
        })
        if (user.role <= Roles.PartnerAdmin) {
          const filtered = sorted.filter(e => e.role.enum <= Roles.PartnerAdmin); // Filter internal super admins/developers out. Only show basic user, fleet, and partner admins
          setUsers(filtered);
        }
        else {
          setUsers(sorted);
        }
      }
    })
    .catch((err) => console.error("error on getUsers fetch", err));
}
const deleteUser = useCallback((data) => {
  if (data === null || data === undefined)
    console.error("no data for request");
  let url = `${props.apiURL}deleteUser`;
  try {
    fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error") 
          alert(SERVER_ERRORED_MESSAGE);
        else 
          alert('User account has been deleted.')
          getUserRecords();
      });
  } catch (err) {
    console.error("error", err);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[props.apiURL, props.user.token, props.db]);

const deleteUserMembership = useCallback((data) => {
  if (data === null || data === undefined)
    console.error("no data for request");
  data.dbName = props.db;
  let url = `${props.apiURL}deleteUserMembership`;
  try {
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error") {
          alert(SERVER_ERRORED_MESSAGE);
        } else {
          alert('User account has been removed from this database.')
          getUserRecords()
        }
      });
  } catch (err) {
    console.error("error", err);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[props.apiURL, props.user.token, props.db]);

const handleDeleteClick = useCallback((username, userObject) => {
  let answer;
  if (userObject.databases.length === 1) {
    answer = window.confirm(
      `Are you sure you wish to delete the user ${username} from ${dbDisplayName}? This will permanently delete the user account. This action is irreversible.`
    )
  } else {
    answer = window.confirm(
      `Are you sure you wish to delete the user ${username} from ${dbDisplayName}? This will remove the user's access to this database.`
    );
  }

  if (answer) {
    const d = { username: username };
    if (userObject.databases.length === 1) deleteUser(d);
    else deleteUserMembership(d);
    const updatedUserList = users.filter((u) => u.email !== username);
    setUsers(updatedUserList);
  }
},[deleteUserMembership, deleteUser, users, dbDisplayName]);

  const handleSave = () => {
    let updatedRawUsers = users.filter((u) => u.mutated);
    let updatedUsers = []

    if(updatedRawUsers.length === 0 ) {
      alert('No user product selections have been changed.');
      return;
    }

    updatedRawUsers.forEach((u) => {
      let simplifiedUser = {"email":u.email}
      //Check the original product list to see what products have been added/deleted
      simplifiedUser.products_added = u.products.filter((p) => !u.original_product_list.includes(p));
      simplifiedUser.products_deleted = u.original_product_list.filter((p) => !u.products.includes(p))
      // Reset the list
      u.original_product_list = u.products
      // add the cleaned up user to the list to send to the API
      updatedUsers.push(simplifiedUser)
    })
    
    let url = `${apiURL}updateUserProducts?dbName=${db}`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
      body: JSON.stringify(updatedUsers),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "error") {
        alert(SERVER_ERRORED_MESSAGE);
      } else {
        let successAlertMessage = `Product selections were updated for the following users:\n- ${updatedUsers.map((u) => {return u.email}).join("\n- ")}`;
        alert(successAlertMessage);
      }
    })
  }

  function handleProductClick(email, product) {
    skipPageResetRef.current = false;
    let tempUsers = [...users];
    let row = _getTableIndex(email);
    let user = tempUsers[row]
    user.mutated = true
    if (user.products.includes(product)) {
      user.products = user.products.filter((p) => p !== product);
    } else {
      user.products = [...user.products, product]
    }
    setUsers(tempUsers)
  };

  function _getTableIndex(email) {
    const idxArr = users.map((u) => {
      return u.email;
    });
    return idxArr.indexOf(email);
  }

const mappedUsers = useMemo(() => {
  if (!users) { return null }
  const ImmutableUserList = JSON.parse(JSON.stringify(users))

  return ImmutableUserList.filter((u) => {
    let deleteDisabled = false;
    let updateDisabled = false;
    if (user.role <= Roles.PartnerAdmin) { // If partner admin and below, enforce button disable rules
      deleteDisabled = user.role <= u.role.enum; // Disable delete buttons for users at and above the logged in user
      updateDisabled = user.role <= u.role.enum && user.email !== u.email; // Disable update buttons for users at and above the logged in user, except for the logged in user
    }
    // orginal_timestamp is used for table sorting.
    u.original_timestamp = JSON.parse(JSON.stringify(u.last_login))
    u.last_login = formatDateTimeUTC(u.last_login);
    u.role_text = u.role.humanReadable;
    u['ezev-td'] = <BoolTableCell accessor={"ezev-td"} bool={u.products?.includes("ezev-td")} disabled={!databaseProducts.includes("ezev-td") || updateDisabled} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("ezev-td")} handleOnClick={() => { handleProductClick(u.email, 'ezev-td') }} />
    u['ezev-fx'] = <BoolTableCell accessor={"ezev-fx"} bool={u.products?.includes("ezev-fx")} disabled={!databaseProducts.includes("ezev-fx") || updateDisabled} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("ezev-fx")} handleOnClick={() => { handleProductClick(u.email, 'ezev-fx') }} />
    // evse_map = ezio
    u.evse_map = <BoolTableCell accessor={"evse_map"} bool={u.products?.includes("evse-map")} disabled={!databaseProducts.includes("evse-map") || updateDisabled} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("evse-map")} handleOnClick={() => { handleProductClick(u.email, 'evse-map') }} />
    u.ionev = <BoolTableCell accessor={"ionev"} bool={u.products?.includes("ionev")} disabled={!databaseProducts.includes("ionev") || updateDisabled} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("ionev")} handleOnClick={() => { handleProductClick(u.email, 'ionev') }} />
    u.emit = <BoolTableCell accessor={"emit"} bool={u.products?.includes("emit")} disabled={!databaseProducts.includes("emit") || updateDisabled} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("emit")} handleOnClick={() => { handleProductClick(u.email, 'emit') }} />
    u.speedn = <BoolTableCell accessor={"speedn"} bool={u.products?.includes("speedn")} disabled={!databaseProducts.includes("speedn") || updateDisabled} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("speedn")} handleOnClick={() => { handleProductClick(u.email, 'speedn') }} />
    u.idling = <BoolTableCell accessor={"idling"} bool={u.products?.includes("idling")} disabled={!databaseProducts.includes("idling") || updateDisabled} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("idling")} handleOnClick={() => { handleProductClick(u.email, 'idling') }} />
    u.shifted = <BoolTableCell accessor={"shifted"} bool={u.products?.includes("shifted")} disabled={!databaseProducts.includes("shifted") || updateDisabled} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("shifted")} handleOnClick={() => { handleProductClick(u.email, 'shifted') }} />
    u.fueln = <BoolTableCell accessor={"fueln"} bool={u.products?.includes("fueln")} disabled={!databaseProducts.includes("fueln") || updateDisabled} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("fueln")} handleOnClick={() => { handleProductClick(u.email, 'fueln') }} />
    u.admin = <BoolTableCell accessor={"admin"} bool={u.products?.includes("admin")} disabled={!databaseProducts.includes("admin") || updateDisabled || u.role.enum < Roles.FleetAdmin} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("admin")} aboveAccessLevel={u.role.enum < Roles.FleetAdmin} handleOnClick={() => { handleProductClick(u.email, 'admin') }} />
    u.dev_tools = <BoolTableCell accessor={"dev_tools"} bool={u.products?.includes("dev-tools")} disabled={!databaseProducts.includes("dev-tools") || updateDisabled || u.role.enum < Roles.SuperAdminDeveloper} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("dev-tools")} aboveAccessLevel={u.role.enum < Roles.SuperAdminDeveloper} handleOnClick={() => { handleProductClick(u.email, 'dev-tools') }} />
    u.location_edit_tool = <BoolTableCell accessor={'location_edit_tool'} bool={u.products?.includes('location-edit-tool')} disabled={!databaseProducts.includes("location-edit-tool") || updateDisabled || u.role.enum < Roles.SuperAdminDeveloper} updateForbidden={updateDisabled} notPurchased={!databaseProducts.includes("location-edit-tool")} aboveAccessLevel={u.role.enum < Roles.SuperAdminDeveloper} handleOnClick={() => { handleProductClick(u.email, 'location-edit-tool') }} />
    u.update_groups = <TableButton data_testid={"updateGroups-testid"} disabled={updateDisabled} handleClick={() => updateDisabled ? null : showUpdateGroupsModal(u.email)} label={'Update'} />
    u.delete_user = <TableButton data_testid={"deleteUser-testid"} disabled={deleteDisabled} handleClick={() => deleteDisabled ? null : handleDeleteClick(u.email, u)} label={'Delete'} color={'#e01f1f'} />
    return u;
  })
// eslint-disable-next-line react-hooks/exhaustive-deps
},[users, databaseProducts, showUpdateGroupsModal, dbDisplayName])

  // Start of API Calls

  function getDatabaseProducts() {
    fetch(`${apiURL}productsForDatabase?database=${db}`, {
      headers: { Authorization: `Bearer ${user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "error")
          alert(SERVER_ERRORED_MESSAGE);
        else {
          let dbProducts = []
          data?.data?.forEach((p) => {
            dbProducts.push(p.product)
          })
          setDatabaseProducts(dbProducts)
        }
      })
  }

  function getDatabases() {
    let url = `${props.apiURL}getDatabases`;
    try {
      fetch(url, {
        headers: { Authorization: `Bearer ${props.user.token}` },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "error")
            alert(SERVER_ERRORED_MESSAGE);
          else 
            setDatabases(data.data);
        });
    } catch (err) {
      console.error("error", err);
    }
  }

  const getSelectUserDatabases = useCallback(
    (usrs) => {
      if (!usrs) usrs = users;
      if (!usrs || usrs.length < 1) return;
      let completedDBs = [];
      usrs.forEach((u, i) => {
        let url = `${props.apiURL}getDatabasesForUser/${u.email}`;
        try {
          fetch(url, {
            headers: { Authorization: `Bearer ${props.user.token}` },
          })
            .then((resp) => resp.json())
            .then((resp) => {
              if (resp.status === "error")
                alert(SERVER_ERRORED_MESSAGE);
              else {
                let obj = {
                  email: resp.data[0].email,
                  dbs: resp.data.map((db) => {
                    return db.database;
                  }),
                };
                completedDBs.push(obj);
                if (completedDBs.length === usrs.length) setUserDBs(completedDBs);
              }
            });
        } catch (err) { }
      });
    },
    [props.apiURL, props.user.token, users]
  );
  
  const createUserObject = useCallback(() => {
    if (!users || !userDBs || (users.length < 1 && userDBs.length < 1)) return;
    else {
      let arr = users.map((user) => {
        let otherDBs = userDBs.filter((u) => u.email === user.email);
        return {
          admin: user.admin,
          basicUser: user.basic_user,
          fleetAdmin: user.fleet_admin,
          partnerAdmin: user.partner_admin,
          superAdmin: user.super_admin,
          db: user.primary_db,
          developer: user.developer,
          email: user.email,
          lastLogin: user.last_login,
          otherDBs: otherDBs[0] === undefined ? [] : otherDBs[0].dbs,
        };
      });
      setUpdatedUsers(arr);
    }
  }, [userDBs, users]);

  useEffect(() => {
    if (users && userDBs && users.length > 0 && userDBs.length > 0)
      createUserObject();
  }, [users, databases, userDBs, createUserObject]);

  function updatePassword(account, password) {
    let data = { account: account, password: password };
    let url = `${props.apiURL}updateUserPassword/`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error")
          window.alert("There was a server error during your request.");
      });
  }

  function createUser(userData) {
    let url = `${props.apiURL}createUser/`;
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(userData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            window.alert('New user has been added.')
            getUserRecords();
            props.createNewUserPassword(userData.username);
          } else {
            window.alert(SERVER_ERRORED_MESSAGE);
          }
        });
    } catch (err) {
      console.error("err", err);
    }
  }

  // END OF API CALLS

  function createUserHandler(data) {
    createUser(data);
    setShowCreateUserModal(false);
  }
  if (
    !updatedUsers[0] ||
    !updatedUsers[users.length - 1] ||
    !updatedUsers[users.length - 1].otherDBs
  )
    return <Loading />;
  return (
    <>
      <S.TableHeaderContainer>
        <S.AdminTableExplainTextPrimary>List of users with access to {dbDisplayName}.</S.AdminTableExplainTextPrimary>
        <S.AdminTableExplainTextSub>Assign vehicle access by selecting groups. Reach out to your Sawatch account manager to request changes to user access roles.</S.AdminTableExplainTextSub>
        <S.AdminTableExplainList>
          {user.role >= Roles.BasicUser && <S.AdminTableExplainBullets>Basic User: User has view only access and the ability to download reports from the dashboard.</S.AdminTableExplainBullets>}
          {user.role >= Roles.FleetAdmin && <S.AdminTableExplainBullets>Fleet Admin: User has access to view the dashboard, download reports, and adjust limited settings through the Settings Cog.</S.AdminTableExplainBullets>}
          {user.role >= Roles.PartnerAdmin && <S.AdminTableExplainBullets>Partner Admin: User has access to view the dashboard, download report, and adjust multiple settings through the Settings Cog.</S.AdminTableExplainBullets>}
        </S.AdminTableExplainList>
        <S.CtaButtonWrapper>
          <S.CtaButton disabled={groups.length === 0} data-testid={"addUserBtn-testid"} onClick={() => setShowCreateUserModal(!showCreateUserModal)}>Add</S.CtaButton>
          <S.CtaButton onClick={() => handleSave()}>Update Products</S.CtaButton>
        </S.CtaButtonWrapper>
      </S.TableHeaderContainer>
      <AdminTableView 
        columns={adminUsersTableColumns}
        hiddenColumns={hiddenColumns}
        data={mappedUsers}
        stickyCols={2}
        skipPageResetRef={skipPageResetRef}
        noDataMessage={'No users to display. Click "Add" to create a new user.'}
      />
      <>
        {groups && (
          <CreateUserModal
            user={props.user}
            db={props.db}
            dbDisplayName={dbDisplayName}
            groups={groups}
            show={showCreateUserModal}
            handleClose={() => setShowCreateUserModal(!setShowCreateUserModal)}
            createUser={createUserHandler}
            passwordRules={props.passwordRules}
          />
        )}
      </>
      <>
        {showGroupsModal && (
          <UpdateUserGroupsModal
            db={props.db}
            updateGroups={props.updateGroups}
            saveGroupsChange={props.saveGroupsChange}
            show={showGroupsModal}
            user={props.user}
            apiURL={props.apiURL}
            selectedUser={selectedUser}
            groups={groups}
            showUpdateGroupsModal={showUpdateGroupsModal}
          />
        )}
      </>
      <UpdatePasswordModal
        show={showPasswordModal}
        handleClose={() => setShowPasswordModal(!showPasswordModal)}
        user={selectedUser}
        passwordRules={props.passwordRules}
        updatePassword={updatePassword}
      />
    </>
  );
}
