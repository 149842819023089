import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Route, Switch, NavLink, Redirect, BrowserRouter as Router } from "react-router-dom";
import { usePDF } from '@react-pdf/renderer';
import EmitControls from './emit-components/emit-controls';
import Loading from './emit-components/emit-loading';
import Landing from './emit-components/Landing';
import Detail from './emit-components/Detail';
import HomeStatViews from './emit-components/HomeStatViews';
import AltFuelReductions from './emit-components/AltFuelReductions';
import AltFuelDetail from './emit-components/AltFuelDetail';
import Assumptions from './emit-components/Assumptions';
import VehicleTable from './emit-components/emit-vehicle-table';
import EmitProcessor from './emit-components/emit-processor';
import TabHeader from './emit-components/TabHeader';
import ProductHeader from './emit-components/ProductHeader';
import "../component-css/emit-stylesheets/swt-emit.css";
import PDFReport from './emit-components/PDFReport';
import EmissionsBarChart from './emit-components/EmissionsBarChart';
import EmissionsPieChart from './emit-components/EmissionsPieChart';
import EmissionsReductionBarChart from './emit-components/EmissionsReductionBarChart';
import HomeLineGraph from './emit-components/HomeLineGraph';
import Search from './emit-components/Search';
import ExcelDownloadButton from './emit-components/ExcelDownloadButton';
import InfoTooltip from './emit-components/InfoTooltip';
import * as S from '../styles/emit-styles/Emit-styles';
import { FormatGraphData, CompileIntoWeeks, CompileIntoMonths } from './emit-components/UtilityFunctions';



// ** MACROS **


const POUNDS2TONS = function (lbs) { return lbs * 0.0005 };
//const LITERS2GALS = function (liters) { return liters * 0.264172 };
const KM2MILES = function (km) { return km * 0.621371 };
//const GRAMS2POUNDS = function (grams) { return grams * 0.00220462 };

let grams_ghg_kwh = 500;
let default_fuel_cost = 4;

//constants
const m = require('./emit-components/emissions-moves3.0.3.json');
const MOVES_MODEL = csv2JSON(m.moves3.csv);
MOVES_MODEL.CO2 = csv2JSON(m.moves3.co_csv);
const ALL_VEHICLES_GROUP = "swt-vehicles";
const USE_MONTHS_BREAKPOINT = 5;

//New Emit colors:
const LIGHT_BLUE = '#2FB9E9'
const LIGHT_GREEN = '#51BCA4' 
const DARK_GREEN = '#356E77' 
const DARK_BLUE = '#2E4B7C';


const BEV_COLOR = LIGHT_GREEN
const PHEV_COLOR = DARK_GREEN
const GAS_COLOR = LIGHT_BLUE
const DIESEL_COLOR = DARK_BLUE

//Chart color order: [Gas, diesel, bev, phev]
const CHART_COLORS = [GAS_COLOR, DIESEL_COLOR, BEV_COLOR, PHEV_COLOR]
//Reduct Chart Color order = [PHEV,BEV]
const REDUCTION_CHART_COLORS = [PHEV_COLOR, BEV_COLOR]
const BAR_CHART_COLOR = '#335c67';
const PDF_DOWNLOAD_BUTTON_COLOR = DARK_GREEN;
const showHomelineBar = false;

export default function Emit(props) {
  const {user, apiURL, dbName, basename, dbDisplayName} = props;

  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [groups, _setGroups] = useState([]);
  const [group, _setGroup] = useState(null);
  const [groupDisplayName, setGroupDisplayName] = useState('Loading...')
  const [vehicleClasses, _setVehicleClasses] = useState([]);
  const [emissionData, _setEmissionData] = useState(null);
  const [entireFleetVehicleData, setEntireFleetVehicleData] = useState([]);
  const [calcByFuel, _setCalcByFuel] = useState(true);
  const [displayInLbs, _setDisplayInLbs] = useState(false);
  const [settings, _setSettings] = useState([]);
  //const [barChartBase64, _setBarChartBase64] = useState();
  //const [pieChartBase64, _setPieChartBase64] = useState();
  const [idleData, _setIdleData] = useState([]);
  const [graphData, _setGraphData] = useState(null);
  const [selectedVehicleClasses, _setSelectedVehicleClasses] = useState([undefined]);
  const [homeLineGraphRef, setHomeLineGraphRef] = useState();
  const [emissionsBarChartRef, setEmissionsBarChartRef] = useState();
  const [emissionsPieChartRef, setEmissionsPieChartRef] = useState();
  const [emissionsReductionBarChartRef, setEmissionsReductionBarChartRef] = useState();
  const [emissionsReductionPieChartRef, setEmissionsReductionPieChartRef] = useState();
  const [matchingData, setMatchingData] = useState([]);
  const [matchingAltFuelData, setMatchingAltFuelData] = useState([])
  const [altFuelData, setAltFuelData] = useState(null);
  const [showEmissionsByFuelType, setShowEmissionsByFuelType] = useState(false);

  const showMonths = useMemo(() => {
    if(graphData && graphData?.monthlyStartDates?.length >= USE_MONTHS_BREAKPOINT)return true;
    return false;
  },[graphData]);


  const decimalStringSort = useMemo(() => (rowA, rowB, columnId) => {
    const a = rowA.values[columnId];
    const b = rowB.values[columnId];
    if (typeof a === 'string') {
      return parseFloat(a) > parseFloat(b) ? 1 : -1;
    } else {
    return a > b ? 1 : -1;
    }
  },[])

  //Default column width for table is 100
  const MED_COL = 150
  const LARGE_COL = 200

  const vehicleTableColumns = [
    { Header: "Asset ID", accessor: "asset_id", sortType: "basic", width: MED_COL },
    { Header: "VIN", accessor: "vin", sortType: "basic", width: LARGE_COL },
    { Header: "Year", accessor: "year", sortType: "basic" },
    { Header: "Make", accessor: "make", sortType: "basic" },
    { Header: "Model", accessor: "model", sortType: "basic", width: LARGE_COL },
    { Header: "Vehicle Class", accessor: "vehicle_class", sortType: "basic" },
    { Header: "Fuel Type", accessor: "fuelType", sortType: "basic" },
    { Header: "Miles", accessor: "miles", sortType: decimalStringSort },
    { Header: "Emission Ratio", accessor: "emit", sortType: decimalStringSort },
    { Header: "Fuel Economy\n(MPG)", accessor: "fuelEcon", sortType: "basic" },
    { Header: "Idle Hours", accessor: "idle", sortType: decimalStringSort },
    { Header: displayInLbs ? "GHG\n(Lbs)" : "GHG\n(Tons)", accessor: displayInLbs ? "ghgLbs" : "ghgTons", sortType: decimalStringSort },
    { Header: "NOX\n(Lbs)", accessor: "noxLbs", sortType: decimalStringSort },
    { Header: "Carbon Monoxide\n(Lbs)", accessor: "coLbs", sortType: decimalStringSort, width: MED_COL },
    { Header: displayInLbs ? "Carbon Dioxide\n(Lbs)" : "Carbon Dioxide\n(Tons)", accessor: displayInLbs ? "CO2Lbs" : "CO2Tons", sortType: decimalStringSort, width: MED_COL },
    { Header: 'PM 10\u00B5m\n(Grams)', accessor: 'pm10Grams', sortType: decimalStringSort},
    { Header: 'PM 2.5\u00B5m\n(Grams)', accessor: 'pm25Grams', sortType: decimalStringSort}
  ]

  const altFuelTableColumns = [
    { Header: "Asset ID", accessor: "asset_id", sortType: "basic", width: MED_COL },
    { Header: "VIN", accessor: "vin", sortType: "basic", width: LARGE_COL },
    { Header: "Year", accessor: "year", sortType: "basic" },
    { Header: "Make", accessor: "make", sortType: "basic" },
    { Header: "Model", accessor: "model", sortType: "basic", width: LARGE_COL },
    { Header: "Vehicle Class", accessor: "vehicle_class", sortType: "basic" },
    { Header: "Fuel Type", accessor: "fuelType", sortType: "basic" },
    { Header: "Miles", accessor: "miles", sortType: "basic" },
    { Header: displayInLbs ? "GHG Reduction\n(Lbs)" : "GHG Reduction\n(Tons)", accessor: displayInLbs ? "ghgLbsSavings" : "ghgTonsSavings", sortType: decimalStringSort},
    { Header: "NOX Reduction\n(Lbs)", accessor: "noxLbsSavings", sortType: decimalStringSort },
    { Header: "Carbon Monoxide Reduction\n(Lbs)", accessor: "coLbsSavings", sortType: decimalStringSort, width: LARGE_COL },
    { Header: displayInLbs ? "Carbon Dioxide Reduction\n(Lbs)" : "Carbon Dioxide Reduction\n(Tons)", accessor: displayInLbs ? "CO2LbsSavings" : "CO2TonsSavings", sortType: decimalStringSort, width: LARGE_COL },
    { Header: 'PM 10\u00B5m Reduction\n(Grams)', accessor: "pm10GramsSavings", sortType: decimalStringSort, width: LARGE_COL },
    { Header: "PM 2.5\u00B5m Reduction\n(Grams)", accessor: "pm25GramsSavings", sortType: decimalStringSort, width: LARGE_COL },
    { Header: "Fuel Reduction\n(Gallons)", accessor: "fuelGallonsSavings", sortType: decimalStringSort},
    { Header: "Fuel Savings", accessor: "fuelCostSavings", sortType: decimalStringSort}
  ]

  const req = useMemo(() => {
    return {
      user: user,
      apiURL: apiURL,
      dbName: dbName,
      beginDate: beginDate,
      endDate: endDate,
      group: group,
      selectedVehicleClasses: selectedVehicleClasses,
    }
  }, [dbName, apiURL, beginDate, endDate, user, group, selectedVehicleClasses]);

  useEffect(() =>{
      _getBounds(req, function (bounds) {
        setBeginDate(bounds.beginDate);
        setEndDate(bounds.endDate);
        setMinDate(bounds.beginDate);
        setMaxDate(bounds.endDate)
       });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let r = {
      user: user, dbName: dbName, apiURL: apiURL
    }
    const handleGroups = (groups) => { _setGroup(groups[0].id); _setGroups(groups) };
    _getGroups(r, handleGroups);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    _getSettings(req, function (data) {_setSettings(data);grams_ghg_kwh = data.ghg_kwh_gm; default_fuel_cost = data.fuel_cost});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    _getVehicleClasses(req, function (data) { _setSelectedVehicleClasses(data); _setVehicleClasses(data.slice()) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _getEntireFleetVehicleData(req, setEntireFleetVehicleData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const _conformEmissionData = (data, cb) => {

    let tableData = [];
    let altFuelTableData = [];
    if (data.length > 0 && data[0] !== null) {

      //Filter out equipment
      tableData = data.filter(r => (
        typeof r.vehicle_class !== "undefined" &&
        r.vehicle_class !== 'Equipment'
      ));
      //Filter for selectedVehicleClasses
      tableData = _filterForSelectedClasses(req, data);

      //calc emissions values
      tableData = tableData.map((r) => {
        const processor = new EmitProcessor(MOVES_MODEL, { gramsGHGPerKwh: grams_ghg_kwh, calcByFuel });
        r.fuel_cost = r.fuel_cost ? r.fuel_cost : default_fuel_cost;
        r = processor.calculateEmissions(r, { calcByFuel: calcByFuel });
        if (!isFinite(r.emit) && r.km !== 0) r.emit = 100;
        //Assign vehicle fuel type
        if (!r.is_diesel && !r.is_bev && !r.is_phev) r.fuelType = 'Gasoline';
        if (r.is_diesel) r.fuelType = 'Diesel';
        if (r.is_bev) r.fuelType = 'BEV';
        if (r.is_phev) r.fuelType = 'PHEV';
        r.vin = (r.user_defined_vin && r.user_defined_vin !== 'null') ? r.user_defined_vin : r.vin;
        r.user_defined_vin = (r.user_defined_vin || r.user_defined_vin === 'null') ? r.user_defined_vin : r.vin;
        return r;
      });
      //Filter PHEV and BEV data for alt fuel detail tab.
      altFuelTableData = tableData.filter((d) => d.is_phev === true || d.is_bev === true)
    }
    if (altFuelTableData) setAltFuelData(altFuelTableData)
    if (cb) cb(tableData);
  }

  useEffect(()=>{
    if (beginDate && endDate && group && selectedVehicleClasses && calcByFuel) {
      //getting the idle data out of daily summary and into the
      //table data ep requires a join Matt can't get to work accurately
      //so in the interests of getting it all working, we have two fetch calls
      _getIdleData(req, _setIdleData);
      _getGraphData(req, displayInLbs, _setGraphData);
      _getEmissionData(req, (data) => { _conformEmissionData(data, _setEmissionData); });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[beginDate, endDate, group, selectedVehicleClasses, calcByFuel]);


  const mappedData = useMemo(() => {
    if(!emissionData) {return null}
    const immutableData = JSON.parse(JSON.stringify(emissionData))
    const matchedData = matchingData.map((d) => {return d.vin})

    return immutableData.filter((d) => {
      if (matchedData.indexOf(d.vin) < 0) {return null}
      return d
    })
  },[emissionData, matchingData])

  const mappedAltFuelData = useMemo(() => {
  if(!altFuelData) {return null}
  const immutableData = JSON.parse(JSON.stringify(altFuelData))
  const matchedData = matchingAltFuelData.map((d) => {return d.vin})

  return immutableData.filter((d) => {
    if (matchedData.indexOf(d.vin) < 0) {return null}
    return d
  })

  },[altFuelData, matchingAltFuelData])

  const EmitLink = ({ id, label }) => {
    return <NavLink to={`/${id}`} id={id}>{label}</NavLink>;
  };

  const emissionsBarChart = useCallback((newRef) => {if(newRef)setEmissionsBarChartRef(newRef)}, []);
  const emissionsPieChart = useCallback((newRef) => {if(newRef)setEmissionsPieChartRef(newRef)}, []);
  const emissionsReductionBarChart = useCallback((newRef) => {if(newRef)setEmissionsReductionBarChartRef(newRef)}, []);
  const emissionsReductionPieChart = useCallback((newRef) => {if(newRef)setEmissionsReductionPieChartRef(newRef)}, []);
  const homeLineGraph = (newRef) => {if(newRef)setHomeLineGraphRef(newRef)} //retain old ref if given falsey ref -- for pdfs

  const handleNoDataMessageText  = useCallback((tab, type) => {
    let noDataMessage = '';
    let pdfReportNoDataMessage = ''
    if (tab === 'home' || tab === 'emissions-summary' || tab === 'emissions-detail') {
      if (!emissionData || emissionData.length < 1) { 
        noDataMessage= 'No data to display. Try adjusting date or filters.'
        pdfReportNoDataMessage = 'No data to display. Try adjusting date or filters.'
      }
      }
    //No data messaging for alternative fuel vehicle related tabs.
    if (tab === 'emissions-reduction-detail' || tab === 'emissions-reduction-summary') {
      //Check to see if there are alt fuel vehicles in entire fleet
      let totalFleetAltFuelVehicles = entireFleetVehicleData.filter((d) => d.is_phev === true || d.is_bev === true);
      if (totalFleetAltFuelVehicles.length < 1) {
        noDataMessage = 'No alternative fuel vehicles in this fleet.'
        pdfReportNoDataMessage = 'There are no alternative fuel vehicles in this fleet. If there are any questions please contact your administrator.'
      }  else if (!altFuelData || altFuelData.length < 1) {
        noDataMessage = 'No alternative fuel vehicle data. Try adjusting date or filters.'
        pdfReportNoDataMessage = 'There is no alternative fuel vehicle data based on your current filters. Try adjusting your date range or filters to determine your emission reductions.'
      } else if (!emissionData || emissionData.length < 1) {
        noDataMessage = 'No data to display. Try adjusting date or filters.'
        pdfReportNoDataMessage = 'No data to display. Try adjusting date or filters.'
      }
    }
    if (type === 'pdfReport') {
      return pdfReportNoDataMessage
    } else {
      return noDataMessage
    }
  }, [altFuelData, emissionData, entireFleetVehicleData])

  const graphDisplayCheck = useCallback((tab) => {
    if (graphData === null || graphData.length < 1) {
      return false
    } else {
      if (tab === 'home') {
          if (graphData.dailyTotalEmissions.every((d) => d === 0) && graphData.totalDailyEmissionReductions.every((d) => d === 0)) {
            return false;
          } else {
            return true;
          }
        }
      if (tab === 'emissions-reduction-summary') {
        if (graphData.weeklyGHGReduction.every((d) => d === 0)){
          return false;
        } else {
            return true;
          }
        }
      if (tab === 'emissions-summary') {
        if (graphData.weeklyGHG.every((d) => d === 0)) {
          return false;
        } else {
            return true;
          }
        }
      }
    }, [graphData])

  
  // TODO: emissionsSummary should probably be wrapped in a useState hook, to ensure proper state handling
  // Want to maintain stability for now but should re-evaluate how we're handling the below few lines
  let dateRange = '';
  if(beginDate !== null && endDate !== null)dateRange = `${beginDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`;
  const processor = new EmitProcessor(MOVES_MODEL, { gramsGHGPerKwh: grams_ghg_kwh, calcByFuel });
  const emissionsSummary = processor.summarizeEmissions(emissionData, idleData, settings, dateRange);

  const [instance, updateInstance] = usePDF({document: _pdfReport(emissionsSummary, 
    {selectedVehicleClasses: selectedVehicleClasses, vehicleClasses: vehicleClasses, group: groupDisplayName, displayInLbs: displayInLbs},
    {
      homeLineGraph: homeLineGraphRef?.toBase64Image(),
      emissionsBarChart: emissionsBarChartRef?.toBase64Image(),
      emissionsPieChart: emissionsPieChartRef?.toBase64Image(),
      emissionsReductionBarChart: emissionsReductionBarChartRef?.toBase64Image(),
      emissionsReductionPieChart: emissionsReductionPieChartRef?.toBase64Image(),
    },
    handleNoDataMessageText,
    graphDisplayCheck
    )})
  useEffect(()=>{
      // Our current handling of state makes it necessary to provide all params via this call to updateInstance
      // Ezev does not do this but it also uses a setTimeout hook to seemingly wait for the state to be correct, which is not ideal
      // This ensures that the necessary data is provided to the PDF on every render
      updateInstance(_pdfReport(emissionsSummary, 
        {selectedVehicleClasses: selectedVehicleClasses, vehicleClasses: vehicleClasses, group: groupDisplayName, displayInLbs: displayInLbs},
        {
          homeLineGraph: homeLineGraphRef?.toBase64Image(),
          emissionsBarChart: emissionsBarChartRef?.toBase64Image(),
          emissionsPieChart: emissionsPieChartRef?.toBase64Image(),
          emissionsReductionBarChart: emissionsReductionBarChartRef?.toBase64Image(),
          emissionsReductionPieChart: emissionsReductionPieChartRef?.toBase64Image(),
        },
        handleNoDataMessageText,
        graphDisplayCheck
        ))
        //eslint-disable-next-line
       },[displayInLbs, showEmissionsByFuelType, selectedVehicleClasses, groupDisplayName, emissionsBarChart, emissionsBarChartRef,
        emissionsPieChart, emissionsPieChartRef, emissionsReductionBarChart, emissionsReductionBarChartRef, emissionsReductionPieChart, emissionsReductionPieChartRef,
        graphDisplayCheck, handleNoDataMessageText, homeLineGraphRef, vehicleClasses]);

  useEffect(() => {
    let selectedGroup = groups.find((g) => {return g.id === group})
    if(selectedGroup) {setGroupDisplayName(selectedGroup.name)}
  },[group, groups])

  const EmitTabHeader = () => {
    return (
      <TabHeader group={groupDisplayName} beginDate={beginDate} endDate={endDate} />
    )
  }

  const Controls = () => {
    return (
      <S.ControlsColumn>
        <EmitControls
          beginDate={beginDate}
          minDate={minDate}
          maxDate={maxDate}
          setBeginDate={function (e) { setBeginDate(e); }}
          endDate={endDate}
          setEndDate={function (e) { setEndDate(e); }}
          vehicleClasses={vehicleClasses}
          group={group ? group : ''}
          groups={groups}
          handleUnitChange={function (u) { _setDisplayInLbs(u); _getGraphData(req, u, _setGraphData); }}
          handleGroupChange={function (g) { _setGroup(g); }}
          handleModelChange={function (v) { _setCalcByFuel(v); }}
          selectedVehicleClasses={selectedVehicleClasses}
          updateSelectedClasses={function (data) { _setSelectedVehicleClasses(data); }}
          calcByFuel={calcByFuel}
          displayInLbs={displayInLbs}
          showEmissionsByFuelType={showEmissionsByFuelType}
          toggleShowEmissionsByFuelType={() => setShowEmissionsByFuelType(!showEmissionsByFuelType)}
        />
      </S.ControlsColumn>
    )
  }

  const NavElement = () => {
    return(
      <S.NavContainer className={"swt-emit-nav-element"}>
          <EmitLink id={'emit/home'} label={'Home'} />
          <EmitLink id={'emit/emissions-summary'} label={'Emissions Summary'} />
          <EmitLink id={'emit/emissions-detail'} label={'Emissions Detail'} />
          <EmitLink id={'emit/emissions-reduction-summary'} label={'Emissions Reduction Summary'} />
          <EmitLink id={'emit/emissions-reduction-detail'} label={'Emissions Reduction Details'} />
          <EmitLink id={'emit/assumptions'} label={'Assumptions'} />
      </S.NavContainer>
    )
  }

  function createGraphRefs(){
    return(
      <S.HiddenElement>
        <EmissionsBarChart
          graphRef={emissionsBarChart}
          COLOR={BAR_CHART_COLOR}
          title={showMonths ? "Monthly Emissions" : "Weekly Emissions"}
          showMonths={showMonths}
          suggestedMax={1}                  
          data={
            {dailyBarData:graphData.ghg,
             weeklyBarData:graphData.weeklyGHG,
             monthlyBarData:graphData.monthlyGHG,
             barLabel:`GHG ${displayInLbs ? '(Lbs)' : '(Tons)'}`, 
             dailyLabels:graphData.labels,
             weeklyBarLabels:graphData.weeklyStartDates,
             monthlyBarLabels:graphData.monthlyStartDates
            }}
        />
        <EmissionsPieChart
          graphRef={emissionsPieChart}
          COLORS={CHART_COLORS}
          displayUnits={displayInLbs ? 'Lbs' : 'Tons'}
          totalForPercentage={displayInLbs ? emissionsSummary.ghgLbs : emissionsSummary.ghgTons}
          displaySliceLabels={true}
          title="GHG Emissions By Vehicle Fuel Type"
          data={_emissionsPieChartData(emissionsSummary, displayInLbs)}
        />
        <EmissionsReductionBarChart
          graphRef={emissionsReductionBarChart}
          COLOR={BAR_CHART_COLOR}
          title="Weekly Emissions Reduction"
          suggestedMax={1}
          showMonths={showMonths}
          data={{
            barData:graphData, 
            barLabel:`GHG ${displayInLbs ? 'Lbs' : 'Tons'} Reductions`, 
            labels:graphData.labels
          }}
        />
        <EmissionsPieChart
          graphRef={emissionsReductionPieChart}
          COLORS={REDUCTION_CHART_COLORS}
          displayUnits={displayInLbs ? 'Lbs' : 'Tons'}
          displaySliceLabels={true}
          totalForPercentage={displayInLbs ? Number(emissionsSummary.phevGHGLbsSaved) + Number(emissionsSummary.bevGHGLbsSaved) : Number(emissionsSummary.phevGHGTonsSaved) + Number(emissionsSummary.bevGHGTonsSaved)}
          title='GHG Emissions Reduction By Fuel Type'
          data={_emissionsReductionPieChartData(emissionsSummary, displayInLbs)}
        />
         <HomeLineGraph
            graphRef={homeLineGraph}
            showBar={showHomelineBar}
            showMonths={showMonths}
            emissionData={graphData}
            showEmissionsByFuelType={false} 
            displayInLbs={displayInLbs}
          />
    </S.HiddenElement>
    )
  }

  if (!emissionData || !graphData){
    return (
      <S.ProductWrapper>
        <Router basename={basename}>
        <ProductHeader fleetDisplayName={dbDisplayName} />
          <NavElement />
      <S.ContentContainer>
        <S.ContentInnerWrapper>
          <div className="swt-emit-loading"><Loading /></div>
        </S.ContentInnerWrapper>
      </S.ContentContainer>
    </Router>
    </S.ProductWrapper>
    )
  }

  return (
    <S.ProductWrapper>
    <Router basename={basename}>
    <ProductHeader fleetDisplayName={dbDisplayName} />
      <NavElement />
      <S.ContentContainer>
        <S.ContentInnerWrapper>
        <Switch>
          <Route exact path="/emit">
            <Redirect to={`/emit/home`} />
          </Route>
          <Route exact path='/emit/home'>
            {(graphDisplayCheck('home') === false)
            ?
            <>
            <S.TopContentContainer>
              <S.TopContentLeftSide>
              <S.NoDataMessageWrapper>
                <S.NoDataMessage>{handleNoDataMessageText('home')}</S.NoDataMessage>
                <HomeStatViews emissionsSummary={emissionsSummary} displayInLbs={displayInLbs} gridItemsPerRow={3}/>
              </S.NoDataMessageWrapper>
              </S.TopContentLeftSide>
              <S.TopContentRightSide>
                <Controls />
              </S.TopContentRightSide>
            </S.TopContentContainer>
            </>
            :
            <>
            <S.TopContentContainer>
              <S.TopContentLeftSide>
                <S.GraphContainer>
                  <HomeLineGraph 
                    COLORS={CHART_COLORS}
                    showBar={showHomelineBar}
                    showMonths={showMonths}
                    emissionData={graphData}
                    showEmissionsByFuelType={showEmissionsByFuelType} 
                    displayInLbs={displayInLbs}
                  />
                </S.GraphContainer>
              </S.TopContentLeftSide>
              <S.TopContentRightSide>
                <Controls />
                <S.PDFDownloadButton bgColor={PDF_DOWNLOAD_BUTTON_COLOR} href={instance.url} download={'emit-emissions-report-summary.pdf'}>
                  Download PDF
                </S.PDFDownloadButton>
              </S.TopContentRightSide>
            </S.TopContentContainer>

              <S.BottomContentContainer>
                <HomeStatViews emissionsSummary={emissionsSummary} displayInLbs={displayInLbs}/>
              </S.BottomContentContainer>
            {createGraphRefs()}
            </>
            }
          </Route>

          <Route exact path="/emit/emissions-detail">
            <>
              <S.TopContentContainer>
                <S.TopContentLeftSide>
                  <EmitTabHeader />
                  <S.NoDataMessageWrapper>
                    <S.NoDataMessage>{handleNoDataMessageText('emissions-detail')}</S.NoDataMessage>
                    <Detail emissionsData={emissionsSummary} />
                  </S.NoDataMessageWrapper>
                </S.TopContentLeftSide>
                <S.TopContentRightSide>
                  <Controls />
                </S.TopContentRightSide>
              </S.TopContentContainer>

                <S.TableHeaderContainer>
                  <div>

                    <S.TableHeaderTextPrimaryWrapper>
                      <S.TableHeaderTextPrimary>Emission data for vehicles in {dbDisplayName}.</S.TableHeaderTextPrimary>
                      <InfoTooltip textType={'detailTable'} />
                    </S.TableHeaderTextPrimaryWrapper>

                    <S.TableHeaderTextSub>Click a column name to sort the table.</S.TableHeaderTextSub>
                    <Search allValues={emissionData} setMatchingValues={setMatchingData}/>
                  </div>
                  <div>
                    <S.DownloadButtonContainer>
                      <ExcelDownloadButton beginDate={beginDate} endDate={endDate} emissionData={mappedData} group={groupDisplayName} selectedVehicleClasses={selectedVehicleClasses} vehicleClasses={vehicleClasses} displayInLbs={displayInLbs} dbDisplayName={dbDisplayName} displayUnits={displayInLbs ? 'Lbs' : 'Tons'}/>
                    </S.DownloadButtonContainer>
                  </div>
                </S.TableHeaderContainer>

                <VehicleTable
                  columns={vehicleTableColumns}
                  data={mappedData}
                  noDataMessage={handleNoDataMessageText('emissions-detail')}
                />
             </>
          </Route>

          <Route exact path="/emit/emissions-summary">
            <>
              {(graphDisplayCheck('emissions-summary') === false)
              ?
              <>
              <S.TopContentContainer>
                <S.TopContentLeftSide>
                  <EmitTabHeader />
                  <S.NoDataMessageWrapper>
                    <S.NoDataMessage>{handleNoDataMessageText('emissions-summary')}</S.NoDataMessage>
                    <Landing emissionsSummary={emissionsSummary} displayInLbs={displayInLbs}/>
                  </S.NoDataMessageWrapper>
                </S.TopContentLeftSide>
                <S.TopContentRightSide>
                  <Controls />
                </S.TopContentRightSide>
              </S.TopContentContainer>
              </>
              :
              <>
              <S.TopContentContainer>
              <S.TopContentLeftSide>
              <EmitTabHeader />
              <S.NoDataMessage>{handleNoDataMessageText('emissions-summary')}</S.NoDataMessage>
              <S.GraphContainer>
              <EmissionsBarChart
                  COLOR={BAR_CHART_COLOR}
                  title={showMonths ? "Monthly Emissions" : "Weekly Emissions"}
                  showMonths={showMonths}
                  suggestedMax={1}                  
                  data={
                    {dailyBarData:graphData.ghg,
                    weeklyBarData:graphData.weeklyGHG,
                    monthlyBarData:graphData.monthlyGHG,
                    barLabel:`GHG ${displayInLbs ? '(Lbs)' : '(Tons)'}`, 
                    dailyLabels:graphData.labels,
                    weeklyBarLabels:graphData.weeklyStartDates,
                    monthlyBarLabels:graphData.monthlyStartDates
                    }}
                />
                <S.PieChartWrapper>
                  <EmissionsPieChart
                    COLORS={CHART_COLORS}
                    displayUnits={displayInLbs ? 'Lbs' : 'Tons'}
                    totalForPercentage={displayInLbs ? emissionsSummary.ghgLbs : emissionsSummary.ghgTons}
                    title="GHG Emissions By Vehicle Fuel Type"
                    data={_emissionsPieChartData(emissionsSummary, displayInLbs)}
                  />
                </S.PieChartWrapper>
              </S.GraphContainer>
              </S.TopContentLeftSide>
              <S.TopContentRightSide>
                <Controls />
              </S.TopContentRightSide>
              </S.TopContentContainer>

              <S.BottomContentContainer>
                <Landing emissionsSummary={emissionsSummary} displayInLbs={displayInLbs}/>
              </S.BottomContentContainer>
              </>}
            </>

          
          </Route>
          <Route exact path="/emit/emissions-reduction-summary">
          <>
           {(graphDisplayCheck('emissions-reduction-summary') === false)
            ?
            <>
            <S.TopContentContainer>
              <S.TopContentLeftSide>
                <EmitTabHeader />
                <S.NoDataMessageWrapper>
                  <S.NoDataMessage>{handleNoDataMessageText('emissions-reduction-summary')}</S.NoDataMessage>
                  <AltFuelReductions emissionsData={emissionsSummary} displayInLbs={displayInLbs}></AltFuelReductions>
                </S.NoDataMessageWrapper>
              </S.TopContentLeftSide>

              <S.TopContentRightSide>
                <Controls />
              </S.TopContentRightSide>
            </S.TopContentContainer>
            </>
            :
            <>
             <S.TopContentContainer>
                <S.TopContentLeftSide>
                  <EmitTabHeader />
                  <S.NoDataMessage>{handleNoDataMessageText('emissions-reduction-summary')}</S.NoDataMessage>
                  <S.GraphContainer>
                    <EmissionsReductionBarChart
                      COLOR={BAR_CHART_COLOR}
                      title={showMonths ? "Monthly Emissions Reduction" : "Weekly Emissions Reduction"}
                      suggestedMax={1}
                      showMonths={showMonths}
                      data={
                        {barData:graphData, 
                          barLabel:`GHG ${displayInLbs ? 'Lbs' : 'Tons'} Reductions`, 
                          labels:graphData.labels
                        }}
                    />
                    <S.PieChartWrapper>
                      <EmissionsPieChart
                        COLORS={REDUCTION_CHART_COLORS}
                        displayUnits={displayInLbs ? 'Lbs' : 'Tons'}
                        totalForPercentage={displayInLbs ? Number(emissionsSummary.phevGHGLbsSaved) + Number(emissionsSummary.bevGHGLbsSaved) : Number(emissionsSummary.phevGHGTonsSaved) + Number(emissionsSummary.bevGHGTonsSaved)}
                        title='GHG Emissions Reduction By Fuel Type'
                        data={_emissionsReductionPieChartData(emissionsSummary, displayInLbs)}
                      />
                    </S.PieChartWrapper>
                  </S.GraphContainer>
                </S.TopContentLeftSide>

                <S.TopContentRightSide>
                  <Controls />
                </S.TopContentRightSide>
          </S.TopContentContainer>

          <S.BottomContentContainer>
            <AltFuelReductions emissionsData={emissionsSummary} displayInLbs={displayInLbs}></AltFuelReductions>
          </S.BottomContentContainer>
          </>
          }
          </>
          </Route>

          <Route exact path="/emit/emissions-reduction-detail">
            <>
              <S.TopContentContainer>
                <S.TopContentLeftSide>
                  <EmitTabHeader />
                  <S.NoDataMessageWrapper>
                    <S.NoDataMessage>{handleNoDataMessageText('emissions-reduction-detail')}</S.NoDataMessage>
                    <AltFuelDetail vehicleData={emissionsSummary} displayInLbs={displayInLbs}/>
                  </S.NoDataMessageWrapper>
                </S.TopContentLeftSide>

                <S.TopContentRightSide>
                    <Controls />
                </S.TopContentRightSide>
              </S.TopContentContainer>
              <S.TableHeaderContainer>
                  <div>
                    <S.TableHeaderTextPrimaryWrapper>
                      <S.TableHeaderTextPrimary>Emission reduction data for each alternative fuel vehicle in {dbDisplayName}.</S.TableHeaderTextPrimary>
                      <InfoTooltip textType={'ghgEmissionReductionDetails'}/>
                    </S.TableHeaderTextPrimaryWrapper>
                    <S.TableHeaderTextSub>Click a column name to sort the table.</S.TableHeaderTextSub>
                    <Search allValues={altFuelData} setMatchingValues={setMatchingAltFuelData}/>
                  </div>
                  <div>
                    <S.DownloadButtonContainer>
                      <ExcelDownloadButton beginDate={beginDate} endDate={endDate} emissionData={mappedAltFuelData} csvType='altFuel' group={groupDisplayName} selectedVehicleClasses={selectedVehicleClasses} vehicleClasses={vehicleClasses} displayInLbs={displayInLbs} dbDisplayName={dbDisplayName} displayUnits={displayInLbs ? 'Lbs' : 'Tons'}/>
                    </S.DownloadButtonContainer>
                  </div>                </S.TableHeaderContainer>
                <VehicleTable
                  noDataMessage={handleNoDataMessageText('emissions-reduction-detail')}
                  columns={altFuelTableColumns}
                  data={mappedAltFuelData}
                />
            </>
          </Route>

          <Route exact path="/emit/assumptions">
            <Assumptions />
          </Route>

        </Switch>
        </S.ContentInnerWrapper>
      </S.ContentContainer>
    </Router>
    </S.ProductWrapper>
  );
}

// ** PRIVATE FUNCTIONS **

function _pdfReport(emissionsSummary, controlsData, base64Dict, noDataMessage, graphDisplayCheck) {
  return(
    <PDFReport
      selectedVehicleClasses={controlsData.selectedVehicleClasses}
      vehicleClasses={controlsData.vehicleClasses}
      displayInLbs={controlsData.displayInLbs}
      group={controlsData.group}
      emissionsSummary={emissionsSummary}
      barChartBase64={base64Dict.barChart}
      pieChartBase64={base64Dict.pieChart}
      lineChartBase64={base64Dict.homeLineGraph}
      emissionsChartBase64={base64Dict.emissionsBarChart}
      emissionsPieChartBase64={base64Dict.emissionsPieChart}
      emissionsReductionBarChartBase64={base64Dict.emissionsReductionBarChart}
      emissionsReductionPieChartBase64={base64Dict.emissionsReductionPieChart}
      handleNoDataMessageText={noDataMessage}
      graphDisplayCheck={graphDisplayCheck}
    />
  );
}


function _getBounds(req, cb) {
  if (req.beginDate || req.endDate) return;
  fetch(`${req.apiURL}getBounds?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.data.length > 0) {
        const begin = new Date(data.data[0].min)
        const end = new Date(data.data[0].max)
        if (cb) cb({ beginDate: begin, endDate: end });
      }
    });
}

function _getGroups(req, cb) {
  const url = `${req.apiURL}getGroups?dbName=${req.dbName}`;
  fetch(url, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (cb && data && data.data.length > 0) {
        const idx = data.data.findIndex(g => g.id === ALL_VEHICLES_GROUP);
        if (idx > -1) {
          const obj = data.data[idx];
          data.data.splice(idx, 1);
          data.data.unshift(obj);
        };
        cb(data.data);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _emissionsPieChartData(emissionsSummary, displayInLbs){
  if(!emissionsSummary)return {labels:[], data:[]};
  const d = {}
  d.labels = ['Gasoline', 'Diesel', 'BEV', 'PHEV'];
  d.data = [emissionsSummary.gasolineGHGLbs, 
            emissionsSummary.dieselGHGLbs,
            emissionsSummary.evGHGLbs,
            emissionsSummary.phevGHGLbs
   ]
  if(!displayInLbs){
    d.labels = ['Gasoline', 'Diesel', 'BEV', 'PHEV']
    d.data = [emissionsSummary.gasolineGHGTons, 
              emissionsSummary.dieselGHGTons,
              emissionsSummary.evGHGTons,
              emissionsSummary.phevGHGTons]
  }
  return(d)
}

function _emissionsReductionPieChartData(emissionsSummary, displayInLbs) {
  if (!emissionsSummary) return {labels:[], data: []};
  const d = {}
  d.labels = ['PHEV Reductions', 'BEV Reductions']
  d.data = [
    emissionsSummary.phevGHGLbsSaved,
    emissionsSummary.bevGHGLbsSaved
  ]

  if (!displayInLbs) {
    d.labels = ['PHEV Reductions', 'BEV Reductions']
    d.data = [
      emissionsSummary.phevGHGTonsSaved,
      emissionsSummary.bevGHGTonsSaved
    ]
  }

  return d;
}

function _getGraphData(req, displayInLbs, cb){
  const processor = new EmitProcessor(MOVES_MODEL, { gramsGHGPerKwh: grams_ghg_kwh, calcByFuel: true })

  function calculateTotalEmissions (data) {
    let total = 0;
    let totalArr = [];
    data.forEach((item) => {
      total += item;
      totalArr.push(total)
    })
    return totalArr;
  }

  function calculateDailyReductions (reductionsArray0, reductionsArray1) {
    let totalArray = [];
    let total = 0;
    for (let i = 0; i < reductionsArray0.length; i++) {
        let value = reductionsArray0[i];
        if(reductionsArray1)value = (reductionsArray0[i] + reductionsArray1[i]);
        total += value;
        totalArray.push(total);
    }
    return totalArray
  }

  //Set state back to null before fetch to render loading indicator.
  if(cb )cb(null)
  fetch(`${req.apiURL}emitGraphData?dbName=${req.dbName}&group=${req.group}&start=${_formatDateParams(req.beginDate)}&stop=${_formatDateParams(req.endDate)}&selectedVehicleClasses=${req.selectedVehicleClasses}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      // These checks should be reviewed in the future once the API ep is updated and returns a single response type when there is no data. 
      let dataCheck = data.data.every((d) => d.fu_liters === 0 && d.km === 0)
      if (data.data.length === 0 || dataCheck) {
        if(cb) cb([]);
      } else {
      const graphData = {}
      graphData.phevGHGReduction = data.data.map((f)=>{return(processor.co2lbsPerLiter(f.phev_liter_reduction) - processor.kwhToGHGLbs(f.phev_kwh))});
      graphData.bevGHGReduction = data.data.map((f)=>{return (processor.co2lbsPerLiter(f.bev_liter_reduction) - processor.kwhToGHGLbs(f.bev_kwh))});
      graphData.totalGHGReduction = graphData.bevGHGReduction.map((f, idx)=>{return f + graphData.phevGHGReduction[idx]});
      graphData.bevGHG = data.data.map((f) => {return (processor.kwhToGHGLbs(f.bev_kwh))});
      graphData.gasolineGHG = data.data.map((f) => {return (processor.co2lbsPerLiter(f.gasoline_liters))})
      graphData.dieselGHG = data.data.map((f) => {return (processor.co2lbsPerLiterDiesel(f.diesel_liters))})
      graphData.phevGHG = data.data.map((f) => {return (processor.co2lbsPerLiter(f.phev_liters)+ processor.kwhToGHGLbs(f.phev_kwh))});
      
      graphData.ghg = [];
      const ghgObjs = [];
      for(let i=0;i<graphData.gasolineGHG.length;i++){
        graphData.ghg[i] = parseFloat(graphData.bevGHG[i]) + parseFloat(graphData.gasolineGHG[i]) + parseFloat(graphData.dieselGHG[i]) + parseFloat(graphData.phevGHG[i]);
        ghgObjs.push({localized_date: data.data[i].localized_date, ghg: graphData.ghg[i], ghgReduction: graphData.totalGHGReduction[i], phevGHG: graphData.phevGHG[i], phevGHGReduction: graphData.phevGHGReduction[i], gasolineGHG: graphData.gasolineGHG[i], bevGHG: graphData.bevGHG[i], bevGHGReduction: graphData.bevGHGReduction[i], dieselGHG: graphData.dieselGHG[i]})
      }
      const weeklyGHG = CompileIntoWeeks(ghgObjs, 'ghg', req.beginDate, req.endDate);
      const weeklyGHGReduction = CompileIntoWeeks(ghgObjs, 'ghgReduction', req.beginDate, req.endDate);
      const monthlyGHG = CompileIntoMonths(ghgObjs, 'ghg', req.beginDate, req.endDate);
      const monthlyGHGReduction = CompileIntoMonths(ghgObjs, 'ghgReduction', req.beginDate, req.endDate);
      //PHEV*****
      const weeklyPhevGHG = CompileIntoWeeks(ghgObjs, 'phevGHG', req.beginDate, req.endDate)
      const weeklyPhevGHGReduction = CompileIntoWeeks(ghgObjs, 'phevGHGReduction', req.beginDate, req.endDate)
      const monthlyPhevGHG = CompileIntoMonths(ghgObjs, 'phevGHG', req.beginDate, req.endDate)
      const monthlyPhevGHGReduction = CompileIntoMonths(ghgObjs, 'phevGHGReduction', req.beginDate, req.endDate)
      //BEV*********
      const weeklyBevGHG = CompileIntoWeeks(ghgObjs, 'bevGHG', req.beginDate, req.endDate)
      const weeklyBevGHGReduction = CompileIntoWeeks(ghgObjs, 'bevGHGReduction', req.beginDate, req.endDate)
      const monthlyBevGHG = CompileIntoMonths(ghgObjs, 'bevGHG', req.beginDate, req.endDate)
      const monthlyBevGHGReduction = CompileIntoMonths(ghgObjs, 'bevGHGReduction', req.beginDate, req.endDate)
      //GASOLINE******
      const weeklyGasolineGHG = CompileIntoWeeks(ghgObjs, 'gasolineGHG', req.beginDate, req.endDate)
      const monthlyGasolineGHG = CompileIntoMonths(ghgObjs, 'gasolineGHG', req.beginDate, req.endDate)
      //DIESEL********
      const weeklyDieselGHG = CompileIntoWeeks(ghgObjs, 'dieselGHG', req.beginDate, req.endDate)
      const monthlyDieselGHG = CompileIntoMonths(ghgObjs, 'dieselGHG', req.beginDate, req.endDate)



      //GASOLINE*******
      graphData.weeklyGasolineGHG = weeklyGasolineGHG.map((g) => {return g.gasolineGHG})
      graphData.monthlyGasolineGHG = monthlyGasolineGHG.map((g) => {return g.gasolineGHG})
      //BEV*********
      graphData.weeklyBevGHG = weeklyBevGHG.map((g) => {return g.bevGHG})
      graphData.weeklyBevGHGReduction = weeklyBevGHGReduction.map((g) => {return g.bevGHGReduction})
      graphData.monthlyBevGHG = monthlyBevGHG.map((g) => {return g.bevGHG})
      graphData.monthlyBevGHGReduction = monthlyBevGHGReduction.map((g) => {return g.bevGHGReduction})
      //PHEV***********
      graphData.weeklyPhevGHG = weeklyPhevGHG.map((g) =>{return g.phevGHG})
      graphData.weeklyPhevGHGReduction = weeklyPhevGHGReduction.map((g) =>{return g.phevGHGReduction})
      graphData.monthlyPhevGHG = monthlyPhevGHG.map((g) => {return g.phevGHG})
      graphData.monthlyPhevGHGReduction = monthlyPhevGHGReduction.map((g) => {return g.phevGHGReduction})
      //Diesel*******
      graphData.weeklyDieselGHG = weeklyDieselGHG.map((g) => {return g.dieselGHG})
      graphData.monthlyDieselGHG = monthlyDieselGHG.map((g) => {return g.dieselGHG})

      graphData.weeklyGHG = weeklyGHG.map((g)=>{return g.ghg});
      graphData.weeklyGHGReduction = weeklyGHGReduction.map((g)=>{return g.ghgReduction});

      graphData.monthlyGHG = monthlyGHG.map((g)=>{return g.ghg});
      graphData.monthlyGHGReduction = monthlyGHGReduction.map((g)=>{return g.ghgReduction});

      graphData.weeklyStartDates = weeklyGHG.map((g)=>{return g.sunday});
      graphData.monthlyStartDates = monthlyGHG.map((g)=>{return g.moy});

      graphData.miles = data.data.map((f) => {return (KM2MILES(f.km))});
      graphData.ratio = data.data.map((f) => {return (f.fu_liters_actual / f.km)});
      graphData.dailyLabels = data.data.map((f) => {
          let s = f.localized_date.split('T');
          return s[0]
      });
      graphData.weeklyLabels = graphData.weeklyStartDates;
      graphData.monthlyLabels = graphData.monthlyStartDates;


      if(displayInLbs === false){
        graphData.phevGHGReduction = graphData.phevGHGReduction.map((g) => {return POUNDS2TONS(parseFloat(g))})
        graphData.bevGHGReduction = graphData.bevGHGReduction.map((g) => {return POUNDS2TONS(parseFloat(g))})     
        graphData.totalGHGReduction = graphData.totalGHGReduction.map((g) => {return POUNDS2TONS(parseFloat(g))});
        graphData.gasolineGHG = graphData.gasolineGHG.map((g) => {return POUNDS2TONS(parseFloat(g))});
        graphData.dieselGHG = graphData.dieselGHG.map((g)=> {return POUNDS2TONS(parseFloat(g))});
        graphData.phevGHG = graphData.phevGHG.map((g)=> {return POUNDS2TONS(parseFloat(g))});
        graphData.bevGHG = graphData.bevGHG.map((g) => {return POUNDS2TONS(parseFloat(g))});
        
        graphData.ghg = [];
        for(let i=0;i<graphData.gasolineGHG.length;i++){
          graphData.ghg[i] = parseFloat(graphData.bevGHG[i]) + parseFloat(graphData.gasolineGHG[i]) + parseFloat(graphData.dieselGHG[i]) + parseFloat(graphData.phevGHG[i]);
        }
        graphData.weeklyGHG = graphData.weeklyGHG.map((g) => {return POUNDS2TONS(parseFloat(g))});
        graphData.weeklyGHGReduction = graphData.weeklyGHGReduction.map((g) => {return POUNDS2TONS(parseFloat(g))});
        graphData.monthlyGHG = graphData.monthlyGHG.map((g) => {return POUNDS2TONS(parseFloat(g))});
        graphData.monthlyGHGReduction = graphData.monthlyGHGReduction.map((g) => {return POUNDS2TONS(parseFloat(g))});
        //PHEV******
        graphData.weeklyPhevGHG = graphData.weeklyPhevGHG.map((g) =>{return POUNDS2TONS(parseFloat(g))})
        graphData.weeklyPhevGHGReduction = graphData.weeklyPhevGHGReduction.map((g) =>{return POUNDS2TONS(parseFloat(g))})
        graphData.monthlyPhevGHG = graphData.monthlyPhevGHG.map((g) => {return POUNDS2TONS(parseFloat(g))})
        graphData.monthlyPhevGHGReduction = graphData.monthlyPhevGHGReduction.map((g) => {return POUNDS2TONS(parseFloat(g))})
        //GASOLINE******
        graphData.weeklyGasolineGHG = graphData.weeklyGasolineGHG.map((g) => {return POUNDS2TONS(parseFloat(g))})
        graphData.monthlyGasolineGHG = graphData.monthlyGasolineGHG.map((g) => {return POUNDS2TONS(parseFloat(g))})
        //BEV*********
        graphData.weeklyBevGHG = graphData.weeklyBevGHG.map((g) => {return POUNDS2TONS(parseFloat(g))})
        graphData.weeklyBevGHGReduction = graphData.weeklyBevGHGReduction.map((g) => {return POUNDS2TONS(parseFloat(g))})
        graphData.monthlyBevGHG = graphData.monthlyBevGHG.map((g) => {return POUNDS2TONS(parseFloat(g))})
        graphData.monthlyBevGHGReduction = graphData.monthlyBevGHGReduction.map((g) => {return POUNDS2TONS(parseFloat(g))})
        //DIESEL********
        graphData.weeklyDieselGHG = graphData.weeklyDieselGHG.map((g) => {return POUNDS2TONS(parseFloat(g))})
        graphData.monthlyDieselGHG = graphData.monthlyDieselGHG.map((g) => {return POUNDS2TONS(parseFloat(g))})

      }

      graphData.dailyTotalEmissions = calculateTotalEmissions(graphData.ghg);
      graphData.weeklyTotalEmissions = calculateTotalEmissions(graphData.weeklyGHG);
      graphData.monthlyTotalEmissions = calculateTotalEmissions(graphData.monthlyGHG);

      graphData.weeklyTotalPhevGHG = calculateTotalEmissions(graphData.weeklyPhevGHG)
      graphData.weeklyTotalPhevGHGReduction = calculateTotalEmissions(graphData.weeklyPhevGHGReduction)
      graphData.monthlyTotalPhevGHG = calculateTotalEmissions(graphData.monthlyPhevGHG)
      graphData.monthlyTotalPhevGHGReduction = calculateTotalEmissions(graphData.monthlyPhevGHGReduction)

      graphData.weeklyTotalBevGHG = calculateTotalEmissions(graphData.weeklyBevGHG)
      graphData.weeklyTotalBevGHGReduction = calculateTotalEmissions(graphData.weeklyBevGHGReduction)
      graphData.monthlyTotalBevGHG = calculateTotalEmissions(graphData.monthlyBevGHG)
      graphData.monthlyTotalBevGHGReduction = calculateTotalEmissions(graphData.monthlyBevGHGReduction)

      graphData.weeklyTotalGasolineGHG = calculateTotalEmissions(graphData.weeklyGasolineGHG)
      graphData.monthlyTotalGasolineGHG = calculateTotalEmissions(graphData.monthlyGasolineGHG)

      graphData.weeklyTotalDieselGHG = calculateTotalEmissions(graphData.weeklyDieselGHG)
      graphData.monthlyTotalDieselGHG = calculateTotalEmissions(graphData.monthlyDieselGHG)

      graphData.totalDailyEmissionReductions = calculateDailyReductions(graphData.phevGHGReduction, graphData.bevGHGReduction);
      graphData.totalWeeklyEmissionReductions = calculateDailyReductions(graphData.weeklyGHGReduction);
      graphData.totalMonthlyEmissionReductions = calculateDailyReductions(graphData.monthlyGHGReduction);

      if(cb)cb(FormatGraphData(graphData));
    }
    })
}

function _getVehicleClasses(req, cb) {
  fetch(`${req.apiURL}getVehicleClassesPresent?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.data.length > 0) {
        let arr = data.data.map((e) => {
          if (e.vehicle_class === '') e.vehicle_class = 'Unknown';
          if (e.vehicle_class === null) e.vehicle_class = 'Undefined';
          return e.vehicle_class
        });
        const idx = arr.indexOf('Unknown');
        if (idx > -1) {
          const o = arr[idx];
          arr.splice(idx, 1);
          arr.push(o);
        }
        if (cb) cb(arr);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _filterForSelectedClasses(req, data) {
  let arr = [];
  //if the component returned a "false" empty list(call has not finished but nothing is selected)
  if (typeof req.selectedVehicleClasses[0] === "undefined") {
    arr = [];
    // arr = data.data
  }
  //if there are zero or more selections
  else {
    data.forEach((e) => {
      if (e.vehicle_class === null || e.vehicle_class === '') e.vehicle_class = "Undefined";
      if (req.selectedVehicleClasses.indexOf(e.vehicle_class) > -1) {
        arr.push(e);
      }
    });
  }
  return arr;
}

function _getEmissionData(req, cb) {
  fetch(`${req.apiURL}emitDataForFleet?dbName=${req.dbName}&group=${req.group}&start=${_formatDateParams(req.beginDate)}&stop=${_formatDateParams(req.endDate)}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.data.length > 0) {
        if(cb) cb(data.data);
      }
      else {
        if (cb) cb([]);
      }
    });
}
//Used to get all vehicles in fleet with no control filters which is needed for the handling of the no data messages.
function _getEntireFleetVehicleData(req, cb) {
  fetch(`${req.apiURL}emitDataForFleet?dbName=${req.dbName}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (data && data.data.length > 0) {
        if(cb) cb(data.data);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _getIdleData(req, cb) {
  fetch(`${req.apiURL}idleDataForFleet?dbName=${req.dbName}&start=${_formatDateParams(req.beginDate)}&stop=${_formatDateParams(req.endDate)}`, {
    headers: { Authorization: `Bearer ${req.user.token}` },
  })
    .then((resp) => resp.json())
    .then((data) => {
      if (cb && data && data.data.length > 0) {
        cb(data.data);
      }
      else {
        if (cb) cb([]);
      }
    });
}

function _getSettings(req, cb) {
  fetch(`${req.apiURL}getSettings?dbName=${req.dbName}`,
    { headers: { Authorization: `Bearer ${req.user.token}` } })
    .then((resp) => resp.json())
    .then((data) => {
      if (cb && data && data.data.length > 0) {
        cb(data.data[0]);
      }
    })
    .catch((error) => console.error("Error: " + error));
}

function _formatDateParams(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

function csv2JSON(csv) {

  const lines = csv.split("\n");
  const res = [];
  const headers = lines[0].split(",");

  for (var i = 1; i < lines.length; i++) {
    let obj = {};
    let currentline = lines[i].split(",");
    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }
    res.push(obj);
  }
  return res;
}
