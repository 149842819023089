const INVALID_INPUTS = ['e', 'E', '+', '-']

function FormatCommas(value) {
  if (value === null || value === undefined) return;
  // if number is decimal, toFixed
  if (parseFloat(value) % 1 !== 0) {
    value = parseFloat(value).toFixed(2)
  }
  // add commas if needed
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export default function formatDateTime(start) {
  if(!start) return;
  var str = start.split("T");
  var date = str[0].split("-");
  var month = getMonth(date[1]);
  date = `${month} ${date[2]}`;  // month day
  var time = str[1].split(".")[0];
  time = `${time.split(":")[0]}:${time.split(":")[1]}`;
  // if no time, just date, don't display
  if(time === "00:00")time='';
  if(time)time += (parseInt(time) < 12) ? "am" : "pm";
  return `${date} ${time}`;
}

//Function below converts time from UTC to users local timezone. 
export function formatDateTimeUTC(value) {
  if (!value) return null;

  const res = new Date(value);
  const offset = new Date().getTimezoneOffset()
  const userTimezoneTimestamp = new Date(res.setMinutes(res.getMinutes() - offset)).toLocaleString()
  const date = userTimezoneTimestamp.split(",")[0];
  const time = userTimezoneTimestamp.split(",")[1];
  
  //These variables below are used to remove the seconds that come with new Date object. 
  let hours = time.split(":")[0]
  let minutes = time.split(":")[1]
  let timePeriod = time.split(":")[2].split(" ").slice(1).toString();
  return `${date} - ${hours}:${minutes} ${timePeriod}`;
}

export function capitalizeFirstLetter(input){
  const upper = input.charAt(0).toUpperCase() + input.substring(1);
  return upper;
}

export function preventNonIntegers(e) {
  if(INVALID_INPUTS.includes(e.key)) { // Prevent all invalid int chars
    e.preventDefault()
  }
}

export function preventPasteNonIntegers(e) {
  const clipboardData = e.clipboardData;
  let data = clipboardData.getData('text').split('')
  data.forEach((l) => {
    if(INVALID_INPUTS.includes(l)) { // Prevent all invalid int chars
      e.preventDefault()
    }
  })
};

export function roundObjValues(obj) {
  let valuesToRound = [
    'msrp', 'net_price', 'expected_residual_value', 'maint_per_km', 'insurance', 'battery_kwh', 'battery_kwh_usable',
    'mpg_c', 'mpg_h', 'disp', 'tank_liters', 'cyl', 'weight', 'price', 'bev_purchase_price', 'ice_purchase_price', 'phev_purchase_price',
    'ev_curb_lbs', 'ev_loaded_lbs', 'ice_curb_lbs', 'ice_loaded_lbs', 'level_2_rate', 'level_3_rate', 'fuel_cost', 'actual_purchase_price',
    'kwh_cost', 'ghg_kwh_gm', 'scc', 'battery_capacity', 'usable_kwh', 'ev_yr_km_target']
  Object.keys(obj).forEach((a) => {
    if (valuesToRound.includes(a)) {
      if(obj[a]) {
        obj[a] = roundSingleValue(obj[a], 4)
      }
    }
  })
  return obj;
}

export function roundSingleValue(value, exp) {
  if (typeof exp === 'undefined' || +exp === 0)
    return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
    return NaN;
  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
}

export function formatDecimal(val, numOfDecimals) {
  if(val.includes('.')) {
    let int = val.split('.')[0]
    let dec = val.split('.')[1].slice(0,numOfDecimals)
    return `${int}.${dec}`
   }
   return val
}

// convenience method to sort products on their stack rank in the menu bar and landing page
function productStackRank(a, b) {
  const va = a.stack_rank;
  const vb = b.stack_rank;
  let comparison = 0;
  if (va > vb) {
    comparison = -1;
  } else if (va < vb) {
    comparison = 1;
  }
  return comparison;
}

export function filterProducts(viewAvailable, products, db_is_telematics) {
  // ensure that a product matches the view for available/not, and [ has always_show = true, OR is ezev fx/td depending on db operational data type ]
  return products.filter(p=> (viewAvailable ? p.available : !p.available) && (p.always_show || ( db_is_telematics ? p.product === 'ezev-td' : p.product === 'ezev-fx'))).sort(productStackRank);
}

export function getMonth(value) {
  var month;
  switch (value) {
    case "01":
    case "1":
      month = "Jan";
      break;
    case "02":
    case "2":
      month = "Feb";
      break;
    case "03":
    case "3":
      month = "Mar";
      break;
    case "04":
    case "4":
      month = "Apr";
      break;
    case "05":
    case "5":
      month = "May";
      break;
    case "06":
    case "6":
      month = "Jun";
      break;
    case "07":
    case "7":
      month = "Jul";
      break;
    case "08":
    case "8":
      month = "Aug";
      break;
    case "09":
    case "9":
      month = "Sep";
      break;
    case "10":
      month = "Oct";
      break;
    case "11":
      month = "Nov";
      break;
    case "12":
      month = "Dec";
      break;
    default:
      month = "";
  }
  return month;
}

export function convertISOToExcelSerial(timestamp, calcOffsetFromEventDate) {
  const utcDate = new Date(timestamp); // Parse the ISO 8601 timestamp to a JavaScript Date object
  const offset = (calcOffsetFromEventDate ? utcDate.getTimezoneOffset() : new Date().getTimezoneOffset())* 60000; // determine the offset by the current user's timezone
  const localDate = new Date(utcDate.getTime() - offset); // Convert UTC to local time

  const excelBaseDate = new Date(Date.UTC(1899, 11, 30, 0, 0, 0)); // Excel's date serial number starts on December 30, 1899, for compatibility reasons
  const excelSerialDate = (localDate - excelBaseDate) / (24 * 60 * 60 * 1000); // Subtract the timezone offset to get the time in UTC, then convert to Excel serial

  return excelSerialDate;
}

export function generateExcelDateFormat(settings) {
  let dayFormatString = settings.date_display;
  let timeFormatString = "";
  
  switch(settings.ts_display){
      case "hh:mm:ss xm":
          timeFormatString= "hh:mm:ss AM/PM"; 
          break;
      case "hh:mm:ss":
          timeFormatString= "HH:mm:ss";
          break;
      case "hh:mm xm":
          timeFormatString= "hh:mm AM/PM";
          break; 
      case "hh:mm":
          timeFormatString= "HH:mm";
          break;
      default:
          timeFormatString= "HH:mm:ss";
          break;
  }
  return `${dayFormatString} - ${timeFormatString}`
}

export function FormatMonetaryValue(value,currency_symbol) {
  const FormattedValue = FormatCommas(value);
  if (value < 0) {
    return `-${currency_symbol}${FormattedValue.slice(1)}`;
  } else {
    return `${currency_symbol}${FormattedValue}`;
  }
}

export function calculateProjectedSavings(activity) {
  let totalSavings = activity
    .filter((vehicle) => (vehicle.is_ev_recommendation))
    .reduce((previousValue, currentValue) => (previousValue += currentValue.rec_lt_net_savings), 0);
  var resultNegative = false;
  if (totalSavings > 0) {
    resultNegative = true;
  }
  totalSavings = Math.abs(totalSavings)
  if (totalSavings <= 0) {
    totalSavings = 0;
  }
  else if (totalSavings < 10000) {
    totalSavings = Math.round(Math.round(totalSavings / 1000) * 1000);
  }
  else {
    totalSavings = Math.round(Math.round(totalSavings / 10000) * 10000);
  }

  return totalSavings * (resultNegative ? -1 : 1);
}

//Used to make sure vehicle & candidate classes match to an option in the vehicle class dropdowns
export function determineVehicleClassValue(vc, vehicleClasses) {
    if(vehicleClasses.includes(vc)) {
      return vc;
    } else if (vc === null || vc === '' || vc.toLowerCase() === 'none') {
      return 'none';
    } else {
      return 'invalid'
    }
}